// This file was automatically generated from admin.copymarketingsettings.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.CopyMarketingSettings = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.CopyMarketingSettingsBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.CopyMarketingSettingsBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="copymarketingsettings-page"><h3>Copy Marketings Settings</h3>', (opt_data.content.error_message) ? '<div class="error-message">' + soy.$$escapeHtml(opt_data.content.error_message) + '</div>' : '', (opt_data.content.success_message) ? '<div class="gold-message">' + soy.$$escapeHtml(opt_data.content.success_message) + '</div>' : '');
  if (opt_data.content.warning_venue_names) {
    output.append('<div class="error-message">There are some venues with Auto Tags deactivated and therefore will be copied but not appear available to the venue to use. <br/>The following venues have custom auto tags deactivated:<br/>');
    var nameList695 = opt_data.content.warning_venue_names;
    var nameListLen695 = nameList695.length;
    for (var nameIndex695 = 0; nameIndex695 < nameListLen695; nameIndex695++) {
      var nameData695 = nameList695[nameIndex695];
      output.append(soy.$$escapeHtml(nameData695), '<br/>');
    }
    output.append('<br><h4>Please activate auto tags on these venues to gain access.</h4></div>');
  }
  output.append('<form class="ajaxify" name="marketingSettings" method="post" action="/admin/copy_marketing_settings"><div><h4>1. Select venues to update</h4><table style="width:100%;"><tr><td class="form-label">From Venue:</td><td><select name=\'selected_venue\' id=\'selected_venue\' onchange="updateEmailCampaigns()">');
  var venueList701 = opt_data.content.venues_list;
  var venueListLen701 = venueList701.length;
  for (var venueIndex701 = 0; venueIndex701 < venueListLen701; venueIndex701++) {
    var venueData701 = venueList701[venueIndex701];
    output.append((venueData701.name == opt_data.content.default_venue.name && ! opt_data.content.selected_venue) ? '<option value=' + soy.$$escapeHtml(venueData701.id) + ' selected>' + soy.$$escapeHtml(venueData701.name) + '</option>' : (opt_data.content.selected_venue == venueData701.id) ? '<option value=' + soy.$$escapeHtml(venueData701.id) + ' selected>' + soy.$$escapeHtml(venueData701.name) + '</option>' : '<option value=' + soy.$$escapeHtml(venueData701.id) + '>' + soy.$$escapeHtml(venueData701.name) + '</option>');
  }
  output.append('<select></td></tr><tr><td class="form-label">To Venues:<div style="font-style:italic;color:#999;">(urlkeys or IDs, separated by newlines/commas/spaces)</div></td><td><textarea name="to_venue_urlkeys_or_ids" style="width:100%; height:100px;"></textarea></td><tr></table></div><div><h4>2. Select which group(s) of settings to copy over</h4><div class="float-right"><a href="javascript:selectAllVenueSettings();">Select All</a> | <a href="javascript:deselectAllVenueSettings();">Deselect All</a></div><h5>Auto-Tags</h5><div><label><input type="checkbox" name="copy_standard_autotags" value="true" class="marketing-settings-checkbox" /> Standard Auto-Tags</label></div><div><label><input type="checkbox" name="copy_custom_autotags" value="true" class="marketing-settings-checkbox" /> Custom Auto-Tags</label></div>', (opt_data.content.automated_emails.length) ? '<h5>Automated Emails</h5>' : '');
  var campaign_nameList732 = opt_data.content.automated_emails;
  var campaign_nameListLen732 = campaign_nameList732.length;
  for (var campaign_nameIndex732 = 0; campaign_nameIndex732 < campaign_nameListLen732; campaign_nameIndex732++) {
    var campaign_nameData732 = campaign_nameList732[campaign_nameIndex732];
    Nightloop.Templates.Admin.CopyMarketingSettingsCheckbox({label: campaign_nameData732, name: campaign_nameData732}, output);
  }
  output.append((opt_data.content.email_campaigns.length) ? '<h5>Email Campaigns</h5>' : '');
  var campaign_nameList740 = opt_data.content.email_campaigns;
  var campaign_nameListLen740 = campaign_nameList740.length;
  for (var campaign_nameIndex740 = 0; campaign_nameIndex740 < campaign_nameListLen740; campaign_nameIndex740++) {
    var campaign_nameData740 = campaign_nameList740[campaign_nameIndex740];
    Nightloop.Templates.Admin.CopyMarketingSettingsCheckbox({label: campaign_nameData740, name: campaign_nameData740}, output);
  }
  output.append('</div><div class="float-right"><button id="submit-button">Copy/Overwrite Marketing Settings (no turning back!!)"</button></div><div class="clearfix"></div></form></div><script type=\'text/javascript\'>\n      $(document).ready(function() {\n        window.selectAllVenueSettings = function() {\n          $(\'input.marketing-settings-checkbox\').prop(\'checked\', true);\n        };\n        window.deselectAllVenueSettings = function() {\n          $(\'input.marketing-settings-checkbox\').prop(\'checked\', false);\n        };\n      window.updateEmailCampaigns = function() {\n        var selectedVenue = $(\'#selected_venue :selected\').val();\n        window.location.replace(window.location.origin + \'/admin/copy_marketing_settings?selected_venue=\' + selectedVenue);\n      }\n      });\n    function submitForm() { document.marketingSettings.submit(); }\n    var submitFormThrottled = _.throttle(submitForm, 10000);\n    document.getElementById(\'submit-button\').addEventListener(\'click\', function(event) {\n      event.preventDefault();\n      submitFormThrottled();\n    });\n      <\/script>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.CopyMarketingSettingsCheckbox = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div><label><input type="checkbox" name="', soy.$$escapeHtml(opt_data.name), '" value="true" class="marketing-settings-checkbox" /> ', soy.$$escapeHtml(opt_data.label), '</label></div>');
  return opt_sb ? '' : output.toString();
};
