// This file was automatically generated from admin.conciergeuser.create.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.ConciergeUserCreate = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.ConciergeUserCreateBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ConciergeUserCreateBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="content-header">Concierge-User-Access</div><h2>Creating concierge/user access for user ', soy.$$escapeHtml(opt_data.content.user.first_name), ' ', soy.$$escapeHtml(opt_data.content.user.last_name), '</h2><form class="ajaxify" method="post" action="/admin/conciergeuser/create/', soy.$$escapeHtml(opt_data.content.form.fields.user_id.value), '"><input type="hidden" name="', soy.$$escapeHtml(opt_data.content.form.fields.user_id.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.user_id.value), '"><div>Concierge<br/><select name="', soy.$$escapeHtml(opt_data.content.form.fields.concierge_id.name), '">');
  var conciergeList576 = opt_data.content.all_concierges;
  var conciergeListLen576 = conciergeList576.length;
  for (var conciergeIndex576 = 0; conciergeIndex576 < conciergeListLen576; conciergeIndex576++) {
    var conciergeData576 = conciergeList576[conciergeIndex576];
    output.append('<option value="', soy.$$escapeHtml(conciergeData576.id), '">', soy.$$escapeHtml(conciergeData576.name), '</option>');
  }
  output.append('</select></div><div>Privilege Level<br/><select name="', soy.$$escapeHtml(opt_data.content.form.fields.privilege_level.name), '">');
  var choiceList586 = opt_data.content.form.fields.privilege_level.choices;
  var choiceListLen586 = choiceList586.length;
  for (var choiceIndex586 = 0; choiceIndex586 < choiceListLen586; choiceIndex586++) {
    var choiceData586 = choiceList586[choiceIndex586];
    output.append('<option value="', soy.$$escapeHtml(choiceData586[0]), '" ', (choiceData586[0] == opt_data.content.form.fields.privilege_level.value) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(choiceData586[1]), '</option>');
  }
  output.append('</select></div><table><tr><td>Job Title:  </td><td><label><input type="text" name="', soy.$$escapeHtml(opt_data.content.form.fields.job_title.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.job_title.value), '"/></label></td></tr></table><div class=\'italics\'>----- FINE GRAINED FEATURES -----</div><br/><table><tr><td>administrate user access:  </td><td><label><input type="radio" name="', soy.$$escapeHtml(opt_data.content.form.fields.f_user_admin.name), '" value=""', (opt_data.content.form.fields.f_user_admin.value == '') ? 'checked="checked"' : '', '/> Default</label> <label><input type="radio" name="', soy.$$escapeHtml(opt_data.content.form.fields.f_user_admin.name), '" value="1"', (opt_data.content.form.fields.f_user_admin.value == '1') ? 'checked="checked"' : '', '/> Yes</label> <label><input type="radio" name="', soy.$$escapeHtml(opt_data.content.form.fields.f_user_admin.name), '" value="0"', (opt_data.content.form.fields.f_user_admin.value == '0') ? 'checked="checked"' : '', '/> No</label></td></tr></table><br/><br/><input type="submit" name="submit" value="Submit" /></form>');
  return opt_sb ? '' : output.toString();
};
