// This file was automatically generated from widget.followers.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Widget == 'undefined') { Nightloop.Templates.Widget = {}; }


Nightloop.Templates.Widget.Followers = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="followers-component">', (opt_data.title) ? '<div class=\'title\'>Followers</div>' : '', '<span class="frozen-followers-area">');
  var followerList6281 = opt_data.frozenFollowers;
  var followerListLen6281 = followerList6281.length;
  for (var followerIndex6281 = 0; followerIndex6281 < followerListLen6281; followerIndex6281++) {
    var followerData6281 = followerList6281[followerIndex6281];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData6281.initials), '<em class=\'name\'>', soy.$$escapeHtml(followerData6281.full_name), '</em></span>');
  }
  output.append('</span><span class="followers-area">');
  var followerList6289 = opt_data.followers;
  var followerListLen6289 = followerList6289.length;
  for (var followerIndex6289 = 0; followerIndex6289 < followerListLen6289; followerIndex6289++) {
    var followerData6289 = followerList6289[followerIndex6289];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData6289.initials), '<span class="del follower-remove" fid="', soy.$$escapeHtml(followerData6289.id), '">x</span>', (opt_data.includeInput) ? '<input type="hidden" name="fid" value="' + soy.$$escapeHtml(followerData6289.id) + '" />' : '', '<em class=\'name\'>', soy.$$escapeHtml(followerData6289.full_name), '</em></span>');
  }
  output.append('</span><span id="insert-followers" class="add"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/add_2x.png" /><select id="followers-select"></select></span></div>');
  return opt_sb ? '' : output.toString();
};
