// This file was automatically generated from admin.neighborhood.edit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.NeighborhoodEdit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.NeighborhoodEditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.NeighborhoodEditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div><a class="ajaxify" href="/admin/neighborhood/list">&lt;&lt; back to all</a></div><div class="content-header">Neighborhood Edit</div><form class="ajaxify" method="post" action="/admin/neighborhood/edit/', soy.$$escapeHtml(opt_data.content.form.fields.key.value), '"><input type="hidden" name="', soy.$$escapeHtml(opt_data.content.form.fields.key.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.key.value), '">', (opt_data.content.error_message) ? '<div class="error-message">' + soy.$$escapeHtml(opt_data.content.error_message) + '</div>' : '', (opt_data.content.message) ? '<div class="gold-message">' + soy.$$escapeHtml(opt_data.content.message) + '</div>' : '', '<div>Name<br/><input type="text" name="', soy.$$escapeHtml(opt_data.content.form.fields.name.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.name.value), '" /></div><div>Abbreviation<br/><input type="text" name="', soy.$$escapeHtml(opt_data.content.form.fields.abbreviation.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.abbreviation.value), '" /></div><div>Municipality<br/><select name="', soy.$$escapeHtml(opt_data.content.form.fields.municipality.name), '">');
  var municipalityList9222 = opt_data.content.municipalities;
  var municipalityListLen9222 = municipalityList9222.length;
  for (var municipalityIndex9222 = 0; municipalityIndex9222 < municipalityListLen9222; municipalityIndex9222++) {
    var municipalityData9222 = municipalityList9222[municipalityIndex9222];
    output.append('<option value="', soy.$$escapeHtml(municipalityData9222.id), '" ', (municipalityData9222.id == opt_data.content.form.fields.municipality.value) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(municipalityData9222.name), '</option>');
  }
  output.append('</select></div><div>Postal Code (optional, if different from muni postal)<br/><input type="text" name="', soy.$$escapeHtml(opt_data.content.form.fields.postal_code.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.postal_code.value), '" /></div><div>Generated by Google<br/><select name="', soy.$$escapeHtml(opt_data.content.form.fields.google_generated.name), '" disabled>', (opt_data.content.form.fields.google_generated.value == 1) ? '<option value="Yes" selected="selected">Yes</option>' : '', '>', (opt_data.content.form.fields.google_generated.value == 0) ? '<option value="No" selected="selected">No</option>' : '', '></select></div><br/><br/><input type="submit" name="submit" value="Submit" /></form>');
  return opt_sb ? '' : output.toString();
};
