// This file was automatically generated from admin.mergeactuals.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.MergeActuals = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.MergeActualsBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.MergeActualsBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="mergeactuals-page"><div><a href="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue_id), '/edit">&lt;&lt; back to venue edit</a></div><h3>Merge Actuals for ', soy.$$escapeHtml(opt_data.content.venue_name), '</h3>', (opt_data.content.error_message) ? '<div class="error-message">' + soy.$$escapeHtml(opt_data.content.error_message) + '</div>' : '', (opt_data.content.message) ? '<div class="gold-message">' + soy.$$escapeHtml(opt_data.content.message) + '</div>' : '', '<form class="ajaxify" method="post" action="/admin/user/venue/', soy.$$escapeHtml(opt_data.content.venue_id), '/merge_actuals"><table><tr><td> </td><td>From:</td><td> </td><td>To:</td></tr><tr><td>User</td><td>');
  Nightloop.Templates.Admin.OptionSelect({options: opt_data.content.options, filter: 'users', name: 'from_choice_users'}, output);
  output.append('</td><td>User</td><td>');
  Nightloop.Templates.Admin.OptionSelect({options: opt_data.content.options, filter: 'users', name: 'to_choice_users'}, output);
  output.append('</td></tr><tr><td>Booked By</td><td>');
  Nightloop.Templates.Admin.OptionSelect({options: opt_data.content.options, filter: 'bookedby', name: 'from_choice_bookedby'}, output);
  output.append('</td><td>Booked By</td><td>');
  Nightloop.Templates.Admin.OptionSelect({options: opt_data.content.options, filter: 'bookedby', name: 'to_choice_bookedby'}, output);
  output.append('</td></tr><tr><td>System ID</td><td><input type="text" name="from_system_id" /></td><td>System ID</td><td><input type="text" name="to_system_id" /></td></tr></table><input type="submit" value="Merge (no turning back!!)" /></form></div><script type=\'text/javascript\'>\n        $(document).ready(function() {\n            var changeEvent = function (change_id, clear_id) {\n                $(change_id).change(function() {\n                    $(clear_id).val(\'\');\n                });\n            };\n\n            changeEvent(\'#from_choice_users\', \'#from_choice_bookedby\');\n            changeEvent(\'#from_choice_bookedby\', \'#from_choice_users\');\n            changeEvent(\'#to_choice_bookedby\', \'#to_choice_users\');\n            changeEvent(\'#to_choice_bookedby\', \'#to_choice_users\');\n\n        });\n        <\/script>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.OptionSelect = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<select id="', soy.$$escapeHtml(opt_data.name), '" name="', soy.$$escapeHtml(opt_data.name), '"><option value =\'\'></option>');
  var choiceList9023 = opt_data.options;
  var choiceListLen9023 = choiceList9023.length;
  for (var choiceIndex9023 = 0; choiceIndex9023 < choiceListLen9023; choiceIndex9023++) {
    var choiceData9023 = choiceList9023[choiceIndex9023];
    output.append((opt_data.filter == 'users') ? (choiceData9023.is_user) ? '<option value="' + soy.$$escapeHtml(choiceData9023.id) + '">' + soy.$$escapeHtml(choiceData9023.name) + '</option>' : '' : (opt_data.filter == 'bookedby') ? (! choiceData9023.is_user) ? '<option value="' + soy.$$escapeHtml(choiceData9023.id) + '">' + soy.$$escapeHtml(choiceData9023.name) + '</option>' : '' : '');
  }
  output.append('</select>');
  return opt_sb ? '' : output.toString();
};
