// This file was automatically generated from admin.neighborhood.list.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.NeighborhoodList = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.NeighborhoodListBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.NeighborhoodListBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="neighborhoodlist-page"><div class="content-header">Neighborhoods</div><div class="right vpadding"><a id="neigh-add-link" class="ajaxify" href="/admin/neighborhood/edit/">+ create new</a></div>');
  var muni_neighborhoodsList9255 = opt_data.content.neighborhoods_by_muni;
  var muni_neighborhoodsListLen9255 = muni_neighborhoodsList9255.length;
  for (var muni_neighborhoodsIndex9255 = 0; muni_neighborhoodsIndex9255 < muni_neighborhoodsListLen9255; muni_neighborhoodsIndex9255++) {
    var muni_neighborhoodsData9255 = muni_neighborhoodsList9255[muni_neighborhoodsIndex9255];
    output.append('<div class="entity-list-header">', soy.$$escapeHtml(muni_neighborhoodsData9255.muni_name), '</div>');
    var neighborhoodList9259 = muni_neighborhoodsData9255.neighborhoods;
    var neighborhoodListLen9259 = neighborhoodList9259.length;
    for (var neighborhoodIndex9259 = 0; neighborhoodIndex9259 < neighborhoodListLen9259; neighborhoodIndex9259++) {
      var neighborhoodData9259 = neighborhoodList9259[neighborhoodIndex9259];
      output.append((neighborhoodIndex9259 % 2 == 0) ? '<div class="container entity-record row-even"><a class="ajaxify neigh-edit-link" href="/admin/neighborhood/edit/' + soy.$$escapeHtml(neighborhoodData9259.id) + '">' + soy.$$escapeHtml(neighborhoodData9259.name) + '</a></div>' : '<div class="container entity-record row-odd"><a class="ajaxify neigh-edit-link" href="/admin/neighborhood/edit/' + soy.$$escapeHtml(neighborhoodData9259.id) + '">' + soy.$$escapeHtml(neighborhoodData9259.name) + '</a></div>');
    }
    output.append('<div class="right vpadding"><a class="ajaxify" href="/admin/neighborhood/edit/">+ create new</a></div>');
  }
  output.append('</div>');
  return opt_sb ? '' : output.toString();
};
